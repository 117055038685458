import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout"
import * as data from "../assets/js/data"
import * as util from "../common/util"

const Topic = ({ lang }) => {
  const id = util.getQueryParam("id")
  const copy = data.topicCopy[lang][id]
  const links = data.topicLinks[id]
  const videoUrl = data.topicVideoUrls[id]

  const translate = key => copy[key]

  if (data.topicCopy["en"][id] === undefined) {
    if (typeof window !== "undefined") {
      alert("Topic not found! Click OK to go back to the previous page.")
      navigate(-1)
    }
    return null
  }

  return (
    <Layout lang={lang}>
      <section className={`topic__banner o__${id}`}>
        <h1 className="topic__banner-title">{translate("title")}</h1>
      </section>
      <div className="topic">
        <div className={`topic__item o__highlight o__${id}`}>
          <h2>{translate("description")}</h2>
          <p>{translate("body")}</p>
        </div>
        {links && (
          <div className="topic__item">
            {links.map(({ text, url }) =>
              url ? (
                <a
                  className="topic__link"
                  key={url}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ) : (
                <h5>{text}</h5>
              )
            )}
          </div>
        )}
      </div>
      {videoUrl && (
        <div className="topic">
          <div className="topic__item o__video">
            <iframe
              title={translate("title")}
              width="100%"
              height="100%"
              src={videoUrl}
              allowFullScreen
            />
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Topic
